$largeScreen: 900px;
$smallScreen: 500px;

@import 'mixins/noselect';
@import 'mixins/rounded';

@import 'include/colors';
@import 'include/fonts';
@import 'include/button';

main {
	&#main-login {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		#terms {
			h2 {
				font-size: 24px;
				margin-bottom: 10px;
			}
			p {
				margin: 40px 0;
			}
		}

		.lostpassword {
			a {
				text-decoration: none;
			}
			font-weight: 100;
			position: absolute;
			right: 10;
			bottom: 10;
		}

		.message {
			font-weight: 200;
			padding: 10px;
			max-width: 400px;
		}

		#compatibilityWarning {
			display: none;
		}

		#icon {
			@include rounded;
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 120px;
		}

		form#login {
			.name {
				padding-top: 10px;
				font-size: 1.8em;
				text-align: center;
				font-family: $font;
			}

			margin-bottom: 50px;

			#autologin {
				display: inline-block;
			}

			label[for='autologin'] {
				padding-left: 3px;
				font-size: 14px;
			}

			input {
				display: block;
				font-size: 14px;
				margin-top: 10px;
				margin-left: auto;
				margin-right: auto;

				&[type='email'],
				&[type='password'],
				&[type='text'] {
					border-radius: 0;
					background-color: transparent;
					outline: none;
					border: 0px solid;
					padding: 5px;
					margin-bottom: 10px;
				}

				&[type='submit'] {
					margin-left: auto;
					margin-right: auto;
					margin-top: 20px;
				}

				&[name='oldBrowser'] {
					display: none;
				}
			}

			> div {
				text-align: center;

				.cards {
					text-align: left;
				}
			}
		}
	}

	.warning {
		display: flex;
		flex-direction: row;
		border-radius: 5px;
		margin: 10px 20px 5px 20px;
		padding: 5px 20px;
		color: $errorTextColor;
		border: 1px solid $errorLineColor;
		background: $errorBackgroundColor;

		.swal2-error {
			zoom: 0.5;
			display: flex;
		}

		.message {
			color: $errorTextColor;
			padding-left: 15px;
			align-self: center;
		}
	}

	.success {
		display: flex;
		flex-direction: row;
		border-radius: 5px;
		margin: 10px 20px 5px 20px;
		padding: 5px 20px;
		color: $successTextColor;
		border: 1px solid $successLineColor;
		background: $successBackgroundColor;

		.swal2-success {
			zoom: 0.5;
			display: flex;
		}

		.message {
			color: $successTextColor;
			padding-left: 15px;
			align-self: center;
		}
	}
}

$backgroundColor: #1e1e1e;
$rowOdd: #323232;
$rowEven: #1a1a1a;
$lineColor: #212121;
$baseColor: #333;
$highlightColor: #fb6c25;
$highlightHoverColor: $lineColor;
$textColor: #fff;
$labelColor: gray;
$textColorDisabled: #888;

$headerColor: #191919;
$headerTextColor: #fff;
$headerDisabledTextColor: #ddd;

$sideMenuColor: $headerColor;
$sideMenuColorTextColor: $headerTextColor;

$dropdownBorderColor: $headerColor;
$dropdownBackgroundColor: lighten($backgroundColor, 10);
$dropdownTextColor: $headerTextColor;

$cardBackground: gray;
$cardBackgroundHover: lightgray;

$shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

$analyserWindowBackground: #353535;
$analyserStrokeColor: #000;

@import 'include/skin';
