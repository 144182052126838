$successBackgroundColor:		#28a745;
$successTextColor:				#fff;
$successLineColor:				#28a745;

$warningBackgroundColor:		#ffc107;
$warningTextColor:				#212529;
$warningLineColor:				#ffc107;

$errorBackgroundColor:			#dc3545;
$errorTextColor:				#fff;
$errorLineColor:				#dc3545;

$disabledBackgroundColor:		#353535;
$disabedTextColor:				#fff;
$disabledLineColor:				#404040;

$unknownBackgroundColor:		#3073DE;
$unknownTextColor:				#fff;
$unknownLineColor:				#1c2f51;

$infoBackgroundColor:			#353535;
$infoTextColor:					#fff;
$infoLineColor:					#404040;

$offlineBackgroundColor:		#676767;